import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { navigationRoutes } from '../../routes/navigation/navigation-routes';
import { interval, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { OtpVerificationService } from './otp-verification.service';
import { OtpVerifyResponse } from '../../models/otp-verify-response';
import { Status } from '../../models/response';

@Component({
    selector: 'app-otp-verification',
    standalone: false,
    templateUrl: './otp-verification.component.html',
    styleUrl: './otp-verification.component.scss',
})
export class OtpVerificationComponent implements OnInit, OnDestroy {
    otpVerificationForm: FormGroup;
    navigationRoutes = navigationRoutes;
    returnUrl!: string;
    isResendDisabled = false;
    remainingTime!: number;
    resendIntervalSeconds = 60;
    timerSubscription!: Subscription;
    otpPurpose!:
        | 'login'
        | 'resetPassword'
        | 'new-user-registration'
        | 'partnerProgramLogin'
        | 'partnerProgramRegistration'
        | 'partnerProgramResetPassword';
    flowfor?: string;

    @Input() flowFrom?: string;

    constructor(
        private readonly fb: FormBuilder,
        private readonly otpService: OtpVerificationService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly zone: NgZone,
        private readonly router: Router
    ) {
        this.otpVerificationForm = this.fb.group({
            otp: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        this.otpPurpose = sessionStorage.getItem('otp-purpose') as
            | 'login'
            | 'resetPassword'
            | 'new-user-registration'
            | 'partnerProgramLogin'
            | 'partnerProgramRegistration'
            | 'partnerProgramResetPassword';
        this.returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'] || '/openbankingtestapp';
        this.flowfor = this.activatedRoute.snapshot.queryParams['flowFor'];

        if (this.flowfor === 'login' && this.flowFrom !== 'partnerProgram') {
            this.processLogin();
        } else {
            this.startTimer();
        }

        // harcoded for now
        this.flowfor = 'emailOtp';
    }

    startTimer(): void {
        this.timerSubscription?.unsubscribe();
        this.remainingTime = this.resendIntervalSeconds;
        this.isResendDisabled = true;
        this.timerSubscription = interval(1000).subscribe(() => {
            this.zone.run(() => {
                if (--this.remainingTime <= 0) {
                    this.timerSubscription.unsubscribe();
                    this.isResendDisabled = false;
                }
            });
        });
    }

    getFormattedTime(seconds: number): string {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    resendOTP() {
        const sendOtpBody = {
            email: sessionStorage.getItem('email-id'),
            id: sessionStorage.getItem('otp-user-id'),
            event: sessionStorage.getItem('otp-event'),
        };
        this.otpService.resendOtp(sendOtpBody).subscribe(() => {
            this.startTimer(); // Restart the timer after OTP resend
        });
    }

    submitForm(): void {
        const otpVerifyBody = {
            id: sessionStorage.getItem('otp-user-id'),
            event: sessionStorage.getItem('otp-event'),
            email: sessionStorage.getItem('otp-email'),
            tempOtp: this.otpVerificationForm.value.otp,
            mfaMethod: this.flowfor === 'emailOtp' ? 'email' : 'app',
        };
        if (this.otpVerificationForm.valid) {
            this.otpService.verifyOtp(otpVerifyBody, this.flowFrom).subscribe((res: OtpVerifyResponse) => {
                if (res.status === Status.OK && res.verificationStatus) {
                    this.processLogin();
                }
            });
        }
    }

    processLogin(): void {

        if (this.flowFrom === 'partnerProgram') {
            console.log('this.otpPurpose 1', this.otpPurpose);
            localStorage.setItem('login-flow', 'partner-program');
            this.otpService.processLogin(this.otpPurpose, this.returnUrl ?? '/openbankingtestapp');
        }

        if (this.flowFrom === 'partnerProgramAdmin') {
            console.log('this.otpPurpose 1', this.otpPurpose);
            localStorage.setItem('login-flow', 'admin');
            this.otpService.processLogin(this.otpPurpose, this.returnUrl ?? '/admin-login');
        }
    }

    backFromOtpVerificationScreen(): void {
        this.router.navigate([navigationRoutes.navigateToPartnerProgramLogin]);
    }

    ngOnDestroy() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }
}
