import { Injectable } from '@angular/core';
import { HttpService } from '../../services/http/http.service';
import { SendOtpRequest } from '../../models/send-otp-request';
import { SendOtpResponse } from '../../models/send-otp-response';
import { apiRoutes } from '../../routes/api/api-routes';
import { catchError, tap, throwError } from 'rxjs';
import { UiUtilityService } from '../../services/ui-utility/ui-utility.service';
import { Status } from '../../models/response';
import { OtpVerifyBody } from '../../models/otp-verify-body';
import { OtpVerifyResponse } from '../../models/otp-verify-response';
import { Router } from '@angular/router';
import { navigationRoutes } from '../../routes/navigation/navigation-routes';

@Injectable({
    providedIn: 'root',
})
export class OtpVerificationService {
    constructor(
        private readonly httpService: HttpService,
        private readonly uiUtilityService: UiUtilityService,
        private readonly router: Router
    ) {}

    verifyOtp(otpVerifyBody: OtpVerifyBody, flowFrom?: string) {
        return this.httpService.post<OtpVerifyResponse>(apiRoutes.verifyOtp, otpVerifyBody).pipe(
            tap((res: OtpVerifyResponse) => {
                console.log('OtpVerifyResponse', res);
                if (res.status === Status.OK && res.verificationStatus) {
                    localStorage.setItem('token', res.token);
                    if (flowFrom === 'partnerProgram') {
                        localStorage.setItem('partnerProgramToken', res.token);
                    }
                } else {
                    this.uiUtilityService.handleError(res.responseMessage ?? res.errorMessage);
                }
                return res;
            }),
            catchError((error) => {
                this.uiUtilityService.handleError(error.message);
                return throwError(() => error);
            })
        );
    }

    resendOtp(sendOtpBody: SendOtpRequest) {
        return this.httpService.post(apiRoutes.sendOtp, sendOtpBody).pipe(
            tap((res) => {
                const data = res as SendOtpResponse;
                if (data.status === Status.OK) {
                    this.uiUtilityService.onSuccess(data.responseMessage);
                } else {
                    this.uiUtilityService.handleError(data.responseMessage ?? data.errorMessage);
                }
            }),
            catchError((error) => {
                this.uiUtilityService.handleError();
                return throwError(() => error);
            })
        );
    }

    // Method to handle login process
    processLogin(
        otpPurpose:
            | 'login'
            | 'resetPassword'
            | 'new-user-registration'
            | 'partnerProgramLogin'
            | 'partnerProgramRegistration'
            | 'partnerProgramResetPassword',
        returnUrl: string
    ): void {
        console.log('returnUrl', returnUrl, otpPurpose);
        if (otpPurpose === 'partnerProgramLogin') {
            this.router.navigate([navigationRoutes.navigateToDashboard]);
        } else if (otpPurpose === 'partnerProgramRegistration') {
            this.router.navigate([navigationRoutes.navigateToPartnerProgramTermsAndConditions]);
        } else if (otpPurpose === 'partnerProgramResetPassword') {
            this.router.navigate([navigationRoutes.navigateToPartnerProgramSetPassword]);
        } else if (otpPurpose === 'login') {
            this.router.navigate([navigationRoutes.navigateToAdminHome]);
        } else if (otpPurpose === 'resetPassword') {
            this.router.navigate([navigationRoutes.navigateToSetPassword]);
        } else {
            this.router.navigate([navigationRoutes.navigateToPartnerProgramLogin]);
        }
    }
}
