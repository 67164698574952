import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OtpVerificationRoutingModule } from './otp-verification-routing.module';
import { OtpVerificationComponent } from './otp-verification.component';
import { DependencyModule } from '../../modules/dependency/dependency.module';
import { MaterialModule } from '../../modules/material/material.module';
import { TranslatorModule } from '../../modules/translator/translator.module';

@NgModule({
    declarations: [OtpVerificationComponent],
    imports: [CommonModule, OtpVerificationRoutingModule, DependencyModule, MaterialModule, TranslatorModule],
    exports: [OtpVerificationComponent],
})
export class OtpVerificationModule {}
