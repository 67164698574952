<div class="container-fluid hd-resolution-fixed-width">
    <div class="row otp-verification-div">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <div class="row">
                <div
                    *ngIf="flowFrom !== 'partnerProgram' && flowFrom !== 'partnerProgramAdmin'"
                    class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center otp-verification-form-div"
                >
                    <img
                        class="mt-5 moovet-pay-logo"
                        src="/assets/logos/moovetshop-logo.png"
                        alt="Merchant Portal"
                        [routerLink]="[navigationRoutes.navigateToPartnerProgramLogin]"
                    />
                </div>
                <div
                    [ngClass]="{
                        'col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 ':
                            flowFrom !== 'partnerProgram' && flowFrom !== 'partnerProgramAdmin',
                        'col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10 ':
                            flowFrom === 'partnerProgram' || flowFrom === 'partnerProgramAdmin',
                    }"
                    class="container-fluid otp-verification-form-div"
                >
                    <mat-card
                        [ngClass]="{ 'mt-5 ': flowFrom !== 'partnerProgram' && flowFrom !== 'partnerProgramAdmin' }"
                        class="otp-verification-card d-flex p-5 justify-content-center"
                    >
                        <mat-card-header class="mb-5 d-flex justify-content-center">
                            <mat-card-title *ngIf="flowfor === 'emailOtp'" class="title text-center">{{
                                'otpVerification.title' | translate
                            }}</mat-card-title>
                            <mat-card-title *ngIf="flowfor === 'authenticatorMfa'" class="title text-center">{{
                                'otpVerification.authenticatorTitle' | translate
                            }}</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <form [formGroup]="otpVerificationForm" class="form" (ngSubmit)="submitForm()" #formDir="ngForm" novalidate>
                                <mat-form-field class="text-field-margin" appearance="outline">
                                    <mat-label *ngIf="flowfor === 'emailOtp'">{{ 'otpVerification.enterOtp' | translate }}</mat-label>
                                    <mat-label *ngIf="flowfor === 'authenticatorMfa'">{{
                                        'otpVerification.enterAuthenticatorCode' | translate
                                    }}</mat-label>
                                    <input matInput type="text" formControlName="otp" />
                                    <mat-error>{{ 'otpVerification.otpRequired' | translate }}</mat-error>
                                </mat-form-field>
                                <div *ngIf="flowfor === 'emailOtp'" class="resend-otp-container d-flex justify-content-between">
                                    <div class="resend-otp-label my-1">
                                        <span> {{ 'otpVerification.resendOtpIn' | translate }}</span>
                                        <!-- Display the remaining time -->
                                        <span>{{ getFormattedTime(remainingTime) }}</span>
                                    </div>
                                    <div class="my-1">
                                        <div>
                                            <a
                                                [ngClass]="{
                                                    isResendDisabled: isResendDisabled,
                                                    'primary-link-color ':
                                                        flowFrom !== 'partnerProgram' && flowFrom !== 'partnerProgramAdmin',
                                                    'partner-program-primary-link-color ':
                                                        flowFrom === 'partnerProgram' || flowFrom === 'partnerProgramAdmin',
                                                }"
                                                class="resend-otp-link"
                                                tabindex="0"
                                                (click)="!isResendDisabled && resendOTP()"
                                                (keyup)="!isResendDisabled && resendOTP()"
                                            >
                                                {{ 'otpVerification.resendOtpLink' | translate }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="d-flex align-items-center justify-content-center p-2 mb-2">
                                        <button
                                            [ngClass]="{
                                                'primary-button ': flowFrom !== 'partnerProgram' && flowFrom !== 'partnerProgramAdmin',
                                                'partner-program-primary-button ': flowFrom === 'partnerProgram',
                                            }"
                                            type="submit"
                                            class="otp-verify-btn primary-button w-100"
                                        >
                                            <span>
                                                {{ 'otpVerification.verify' | translate }}
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div class="row row d-flex justify-content-center">
                                    <button class="hide-btn" (click)="backFromOtpVerificationScreen()">
                                        <a mat-button class="back-btn w-100">
                                            {{ 'otpVerification.back' | translate }}
                                        </a>
                                    </button>
                                </div>
                            </form>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>
